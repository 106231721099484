/* -------------------------------------
====== MODAL COMMON STYLES ======
---------------------------------------- */

.pzo__modal {
    display: flex;
    background-color: rgba(22, 35, 106, 25%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    transform: scale(0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    overflow: auto;
}

.pzo__modal.pzo__modal--open {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.pzo__modal .modal {
    min-width: 778px;
    max-width: 1024px;
    background-color: #ffffff;
    margin: auto;
    opacity: 1;
    border-radius: 26px;
    box-shadow: 0px 20px 30px #16236A4D;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.pzo__modal .modal__header {
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent linear-gradient(99deg, #013DEE 0%, #6B6CF9 100%) 0% 0% no-repeat padding-box;
    padding: 20px 24px;
    border-radius: 26px 26px 0 0;
}

.pzo__modal .modal__header .modal__title span {
    display: block;
}

.pzo__modal .modal__header>svg {
    cursor: pointer;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-left: 20px;
}

.pzo__modal .modal__body {
    max-height: 580px;
    position: relative;
    overflow-y: scroll;
}

/* With Slideshow Component */

.pzo__modal .modal-slideshow {
    position: relative;
    padding: 50px 63px 66px 63px;
    overflow: auto;
}

.pzo__modal .screenshot__swiper {
    position: static;
    width: 100%;
    max-height: 340px;
    overflow-y: scroll;
}

.pzo__modal .screenshot__swiper .swiper-wrapper {
    width: 100%;
    max-width: 678px;
    height: 340px;
    width: 100%;
    height: 100%;
}

.pzo__modal .screenshot__swiper img {
    max-width: 678px;
    width: 678px;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.pzo__modal .swiper-button-prev {
    top: calc(50% - 15px);
    left: 24px;
}

.pzo__modal .swiper-button-next {
    top: calc(50% - 15px);
    right: 24px;
}

.pzo__modal .swiper-button-prev::after,
.pzo__modal .swiper-button-next::after {
    position: fixed;
    top: 50%;
    content: '';
    display: inline-block;
    vertical-align: middle;
    font-family: unset;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%236B6CF9'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18' /%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.pzo__modal .swiper-button-next::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%236B6CF9'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3' /%3E%3C/svg%3E%0A");
}

.pzo__modal .swiper-pagination {
    bottom: 30px !important;
}

.pzo__modal .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: var(--violet);
    margin: 0 5px;
}

.pzo__modal .modal-slideshow .cta.cta-negative {
    position: absolute;
    bottom: 73px;
    right: 66px;
    z-index: 99;
}

.pzo__modal .modal-slideshow .screenshot__info-bar {
    width: 100%;
    padding: 0.4rem;
    font-weight: 700;
    color: var(--dark-blue);
    background-color: #e9ebf8;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: 0px 5px 10px #16236A0A;
}

/* With Download Component */

.modal-download {
    padding: 30px 24px;
}

.modal-download .modal-download__title,
.modal-form .modal-form__head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.modal-download .modal-download__title svg,
.modal-form .modal-form__head svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 10px;
}

.modal-download .modal-download__row:not(:last-child) {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 40px;
}

.modal-download .modal-download__row .h3 {
    font-size: 16px;
}

.modal-download .modal-download__row.infos {
    gap: 0 30px;
}

.modal-download .modal-download__row.submit {
    display: flex;
    justify-content: center;
    width: 100%;
}

.modal-download .modal-download__row.submit .cta {
    padding: 10px 60px !important;
}

.modal-download .modal-download__row.submit .cta.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.modal-download .modal-download__row>.modal-download__col {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(22, 35, 106, 0.1);
}

.modal-download .modal-download__row>.modal-download__col span:last-child {
    padding-right: 10px;
}

.modal-download .modal-download__row.selector::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--dark-blue);
    opacity: 0.1;
}

/*.modal-download .modal-download__row.selector>div {
    width: fit-content;
}

 .modal-download .tremor-base {
    border-radius: 19px;
}

.modal-download .tremor-base button {
    border: none;
    outline: none;
    border-radius: 16px;
    background-color: var(--green);
    color: var(--white);
    font-weight: 700;
    padding: 7px 23px;
}

.modal-download .tremor-base button.tr-ring-transparent {
    background-color: unset;
    color: var(--dark-blue);
    font-weight: 300;
} */

/* With Form Component */

.modal-form {
    background-color: #F9F9F9;
    padding: 30px 24px;
    border-radius: 0 0 26px 26px;
}

.modal-form .modal-form__inputs-row {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.modal-form .modal-form__inputs-row span {
    width: 15%;
}

.modal-form .modal-form__inputs-row input,
.modal-form .modal-form__inputs-row textarea,
.modal-form .modal-form__inputs-row .tremor-base {
    font-family: 'Rubik', sans-serif;
    padding: 10px 15px;
    outline: none;
    border: none;
    width: 85%;
    border-radius: 14px;
    box-shadow: 0px 5px 10px #16236A0A;
}

.modal-form .modal-form__inputs-row .tremor-base {
    font-size: 14px;
    padding: 0;
}

.modal-form .modal-form__inputs-row .tremor-base input {
    cursor: pointer;
    width: 100%;
    padding: 8px 15px;
}

.modal-form .modal-form__inputs-row span {
    font-weight: 500;
}

.modal-form .modal-form__inputs-row textarea {
    overflow-x: scroll;
    resize: none;
}

.modal-form .modal-form__inputs-row.textarea {
    align-items: flex-start;
}

.modal-form .modal-form__inputs-row.textarea span {
    padding-top: 15px;
}

.modal-form .modal-form__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-form .modal-form__row .modal-form__files {
    display: flex;
    align-items: center;
}

.modal-form .cta:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.modal-form .modal-form__row .modal-form__files svg {
    vertical-align: middle;
    width: 14px;
    height: 14px;
    margin-right: 10px;
}

/* With Delete User Component */

.pzo__modal .modal.modal__modal-delete-user,
.modal-discard-screenshot {
    text-align: center;
    max-width: unset;
    min-width: unset;
    padding: 30px 20px;
}

.modal-delete-user .h2 {
    font-size: 24px;
    line-height: 1.1;
    display: block;
    text-align: center;
    max-width: 360px;
    margin: auto auto 40px auto;
}

.modal-delete-user .user {
    margin-bottom: 40px;
}

.modal-delete-user .user span {
    font-size: 16px;
    display: block;
}

.modal-delete-user .user .user__name {
    margin-bottom: 20px;
}

.modal-delete-user .ctas {
    display: flex;
    align-items: center;
}

.modal-discard-screenshot.modal-delete-user .ctas {
    max-width: 50%;
    margin: auto;
}

.modal-delete-user .ctas .cta {
    font-size: 18px;
    color: #013DEE;
    border: 1px solid #013DEE;
    background: #ffffff;
    flex: 1;
}

.modal-delete-user .ctas .cta.cta__delete {
    color: #ffffff;
    background: #FF632F;
    border: 1px solid #FF632F;
}

.modal-delete-user .ctas .cta:first-child {
    margin-right: 5px;
}

.modal-delete-user .ctas .cta:last-child {
    margin-left: 5px;
}

/* With Upload Screeenshot Component */
.modal-upload {
    padding: 30px 24px;
    background-color: #ffffff40;
    border-radius: 0 0 26px 26px;
}

.modal-upload__row {
    display: flex;
    align-items: flex-start;
    gap: 50px;
}

.modal-upload__header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 32px;
}

.modal-upload__header svg {
    width: 24px;
    height: 24px;
    stroke: #6B6CF9;
}

.modal-upload__content {
    width: 100%;
}

.modal-upload__uploader {
    cursor: pointer;
    width: 210px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #ffffff;
    border: 1px dashed #6B6CF9;
    border-radius: 26px;
    aspect-ratio: 1 / 1;
}

.modal-upload__uploader>div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.modal-upload__uploader .me-3 {
    font-size: 16px;
    margin-bottom: 8px;
    color: #16236A !important;
}

.modal-upload__uploader .container>div:first-child {
    order: 2;
}

.modal-upload__uploader .container>div:nth-child(2) {
    order: 3;
}

.modal-upload__uploader .container>div:last-child {
    order: 1;
}

/* .modal-upload__uploader .btn.btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    text-indent: -9999px;
    background-color: unset !important;
    border: unset !important;
    outline: none !important;
    margin-bottom: 12px;
}

.modal-upload__uploader .btn.btn-primary svg {
    display: none;
}

.modal-upload__uploader .btn.btn-primary::before {
    content: '';
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%2366DBC8' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 44px;
    height: 44px;
} */

.modal-upload__info {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    margin-bottom: 20px;
    color: #ffffff;
    background-color: #16236A;
    border-radius: 28px;
    font-weight: 500;
}

.modal-upload__info svg {
    stroke: #66DBC8;
    width: 24px;
    height: 24px;
}

.modal-upload__form {
    padding: 24px;
    border-radius: 26px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 20px 30px #013DEE26;
}

.modal-upload__form-row {
    display: flex;
    align-items: center;
}

.modal-upload__form-row:not(:last-child) {
    margin-bottom: 10px;
}

.modal-upload__form-row label {
    font-weight: 600;
    min-width: 96px;
}

.modal-upload__form-row label span {
    color: #FF632F;
    padding-right: 5px;
}

.modal-upload__form-row .tremor-base,
.modal-upload__datepicker .tr-flex > button {
    border: none;
    outline: none;
    box-shadow: unset;
    border-radius: 14px;
}

.modal-upload__form-row input,
.modal-upload__datepicker .tremor-base > .tr-flex {
    width: 100%;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 14px !important;
    box-shadow: 0px 4px 10px #16236A14 !important;
}

.modal-upload__datetime,
.modal-upload__datepicker {
    width: 100%;
}

.modal-upload__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.modal-upload__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    background-color: #66DBC8;
    border-radius: 22px;
    padding: 2rem;
    text-align: center;
  }
  
  .modal-upload__success-icon {
    color: white;
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
  }
  
  .modal-upload__success-message {
    color: white;
    font-size: 1.25rem;
    font-weight: 500;
  } 

  .modal-upload__form-row-checkboxes {
    display: flex;
    align-items: center;
    gap: 0;
    margin-top: 8px;
  }

  .modal-upload__form-row-checkboxes-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .modal-upload__form-row-checkboxes-label input[type="checkbox"] {
    border: 1px solid rgb(222, 222, 219);
  }

  .modal-upload__form-row-checkboxes-label input[type="checkbox"]::after {
    top: 1px;
    left: 5px;
  }